import { useDispatch } from "react-redux";
import { showPopup } from "../../../redux/clientDetails";
import { DisplayContact, DisplayDepartment } from "../../../types";
import styled from "styled-components";
import React, { useState } from "react";
import { Button, H4 } from "@commonsku/styles";
import { ContactSummary } from "./ContactSummary";
import { SubPageHeader } from "../SubPageHeader";
import { SearchBar } from "../SearchBar";
import { searchContacts } from "../utils";
import useClientDetails from "../hooks/useClientDetails";
import { EditContactPopup } from "../popups/EditContactPopup";
import { createAddContactPhone, createDeleteContactPhone, createUpdateFullContact, updateContactTags } from "../../../actions/contact";
import { EditContactFields } from "../../contact/EditContactForm";
import { isString } from "../../../types/type_guards";
import { toast, ToastContainer } from "react-toastify";

const CONTACT_FIELDS_MAP: { [k in keyof EditContactFields]?: keyof DisplayContact } = {
    contact_first_name: "firstName",
    contact_last_name: "lastName",
    contact_position: "position",
    contact_email: "email",
    contact_twitter: "twitter",
    contact_linkedin: "linkedin",
    contact_facebook: "facebook",
    contact_skype: "skype",
    contact_no_marketing: "noMarketing",
} as const;

const CONTACT_UPDATE_FIELDS = [
    'contact_first_name',
    'contact_last_name',
    'contact_email',
    'contact_position',
    'contact_department_id',
    'contact_twitter',
    'contact_linkedin',
    'contact_facebook',
    'contact_skype',
    'contact_default_address_id',
    'contact_no_marketing',
];

type UpdateContactFields = EditContactFields & {
    phone: Array<{
        phone_type: string;
        phone_number: string;
        phone_extension: string;
    }>
};

const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

const Department = styled.div`
    display: flex;
    flex-direction: column;
`;

const DepartmentContacts = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
`;

const DepartmentHeader = styled(H4)`
    &&& {
        margin: 16px 0;
        font-size: 16px;
    }
`;

const HeaderButtons = styled.div`
    &&& {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        gap: 16px;
        height: 40px;
    }
`;

const AddContactButton = styled(Button)`
    &&& {
        display: flex;
        align-items: center;
        line-height: 24px;
        font-size: 14px;
    }
`;

const NoContacts = styled.div`
    &&& {
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-top: 2rem;
    }
`;

interface DepartmentGroups {
    [name: DisplayDepartment["name"]]: {
        contacts: DisplayContact[];
        ordering: DisplayDepartment["ordering"];
    };
}

export const ContactsPage = () => {
    const { contacts, client } = useClientDetails();
    const [searchQuery, setSearchQuery] = useState("");
    const [editingContact, setEditingContact] = useState<DisplayContact | null>(null);
    const dispatch = useDispatch();
    const contactList = searchContacts(searchQuery, contacts);

    const editContact = (contact: DisplayContact) => {
        setEditingContact(contact);
    }

    const createUpdateContactCallback = (contact: DisplayContact) => {
        return (updatedFields: EditContactFields) => {
            const updatedValue = CONTACT_UPDATE_FIELDS.reduce(
                (o, k) => contact[CONTACT_FIELDS_MAP[k]] !== updatedFields[k] ? {
                    ...o,
                    [k]: updatedFields[k]
                } : o,
                {} as UpdateContactFields
            );
            const previousValue = Object.keys(CONTACT_FIELDS_MAP).reduce(
                (o, k) => ({ ...o, [k]: contact[CONTACT_FIELDS_MAP[k]] }),
                {}
            );

            try {
                const newPhones = updatedFields.phones
                    .filter(phone => phone.phone_number.length > 0 || phone.phone_extension.length > 0)
                    .map(phone => {
                        if (
                            isString(phone.phone_number) && 
                            phone.phone_number.length > 0 &&
                            isString(phone.phone_extension) &&
                            phone.phone_extension.length > 0
                        ) {
                            return `${phone.phone_number} ext. ${phone.phone_extension}`;
                        }
                        else if (isString(phone.phone_number)) {
                            return phone.phone_number;
                        }
                        else {
                            return phone.phone_extension;
                        }
                    });

                // Create new phones
                const deltaPhones = [];

                newPhones.forEach((phoneNumber, index) => {
                    const phoneExists = contact.phones.some(oldPhone => (
                        oldPhone.number === phoneNumber &&
                        oldPhone.type === updatedFields.phones[index].phone_type.toUpperCase()
                    ));

                    if (!phoneExists) {
                        deltaPhones.push(updatedFields.phones[index]);
                    }
                });

                deltaPhones.forEach(phone => {
                    dispatch(createAddContactPhone(
                        contact.id,
                        client.id, 
                        [],
                        phone.phone_type,
                        phone.phone_number,
                        phone.phone_extension,
                    ));
                });

                // Delete phones that were removed
                contact.phones.forEach(phone => {
                    const phoneExists = newPhones.some((newPhoneNumber, index) => 
                        phone.number === newPhoneNumber &&
                        phone.type === updatedFields.phones[index].phone_type.toUpperCase()
                    );

                    if (phoneExists) {
                        return;
                    }
 
                    dispatch(createDeleteContactPhone(contact.id, phone.id));
                });

                dispatch(updateContactTags(contact.id, client.id, updatedFields.contact_tags.join(',')));   
                const oldAddressId = contact.address?.id ?? null;
                if (updatedFields.contact_default_address_id && (!oldAddressId || oldAddressId !== updatedFields.contact_default_address_id)) {

                    updatedValue.contact_default_address_id = updatedFields.contact_default_address_id
                }

                dispatch(createUpdateFullContact(contact.id, client.id, previousValue, updatedValue));
                toast.success("Contact updated successfully");
            } catch (e) {
                toast.error("Error updating contact");
            }
        }
    }

    const departmentGroups = contactList
        .filter((contact) => contact.active)
        .reduce((groups: DepartmentGroups, contact) => {
            const department = contact.department;

            if (!groups[department.name]) {
                groups[department.name] = {
                    contacts: [],
                    ordering: department.ordering,
                };
            }

            groups[department.name].contacts.push(contact);
            return groups;
        }, {});

    const inactiveContacts = contactList.filter(c => !c.active);

    const departmentGroupList = Object.entries(departmentGroups)
        .sort((a, b) => a[1].ordering - b[1].ordering)
        .concat(
            inactiveContacts.length > 0 ?
            [["Inactive", { contacts: inactiveContacts, ordering: Number.MAX_VALUE }]] :
            []
        );

    const onSearch = (e: React.FormEvent) => {
        e.preventDefault();

        const value = (e.target as HTMLInputElement).value;
        setSearchQuery(value);
    };

    const clearSearch = () => {
        setSearchQuery("");
    };

    return (
        <>
            {editingContact && (
                <EditContactPopup
                    contact={editingContact}
                    parent_id={client.id}
                    onClosePopup={() => setEditingContact(null)}
                    onEdit={createUpdateContactCallback(editingContact)}
                />
            )}
            <ContactContainer>
                <SubPageHeader title="Contacts">
                    <HeaderButtons>
                        <SearchBar
                            query={searchQuery}
                            onChange={onSearch}
                            onClear={clearSearch}
                        />
                        <AddContactButton onClick={() => dispatch(showPopup("create-contact"))}>
                            Add Contact
                        </AddContactButton>
                    </HeaderButtons>
                </SubPageHeader>
                {contactList.length > 0 ?
                    departmentGroupList.map(([name, { contacts }]) => (
                        <Department key={name}>
                            <DepartmentHeader>{name}</DepartmentHeader>
                            <DepartmentContacts>
                                {contacts.map((contact) => (
                                    <ContactSummary
                                        key={contact.id}
                                        contact={contact}
                                        onClick={editContact}
                                    />
                                ))}
                            </DepartmentContacts>
                        </Department>
                    ))
                    :
                    <NoContacts>
                        {contacts.length > 0
                            ? "No matching contacts found"
                            : `${client.name} has no contacts yet`
                        }
                    </NoContacts>
                }
            </ContactContainer>
            <ToastContainer
                autoClose={3000}
                hideProgressBar={true}
            />
        </>
    );
};
