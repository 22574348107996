import { DisplayClient, DisplayNote } from "../../../types";
import React from "react";
import { Link } from "react-router-dom";
import {
  colors,
  Text,
  EditIcon,
  CopyIcon as BaseCopyIcon,
  Pagination,
} from "@commonsku/styles";
import styled from "styled-components";
import { setEditingDetails, showPopup } from "../../../redux/clientDetails";
import { useDispatch } from "react-redux";
import { formatDateFromString, toTitleCase } from "../../../utils";
import { useScrollCarousel } from "../hooks/useScrollCarousel";
import { ScrollIndicator } from "../ScrollIndicator";

const Header = styled.div`
  padding: 16px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-height: 40px;
  &&& {
    margin-bottom: 16px;
  }
`;

const HeaderText = styled(Text)`
  flex: 1;
  line-height: 24px;
  font-size: 20px;
  font-weight: 600;
  cursor: default;
  color: ${colors.neutrals[100]};
`;

const HeaderButtons = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  color: ${colors.primary1[65]};
`;

const HeaderEditIcon = styled(EditIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ViewAll = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.primary1[65]};
  cursor: pointer;
`;

const ScrollContainer = styled.div`
  position: relative;
`;

const ScrollWrap = styled.div`
  padding: 0 16px 16px 16px;
  overflow-x: scroll;
  white-space: nowrap;
  width: 100%;
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const ClientCarousel = styled.table`
  width: 100%;
  &&& {
    border: none;
    border-collapse: separate;
  }
  tr:hover > td {
    cursor: default;
    background-color: inherit;
  }
`;

const StyledCol = styled.td<{ $bold?: boolean; }>`
  font-weight: ${(props) => (props.$bold ? 500 : 400)};
  min-width: 150px;
  max-width: 300px;
  white-space: nowrap;
  &&& {
    padding: 0 16px 0 0;
  }
  overflow: hidden;
  text-overflow: ellipsis;
`;

const THeadText = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.neutrals[100]};
  vertical-align: middle;
`;

const TbodyText = styled(Text) <{ $blue?: boolean; }>`
  vertical-align: middle;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) =>
    props.$blue ? colors.primary1[65] : colors.neutrals[90]};
`;

const ClientIcon = styled.img`
  vertical-align: middle;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 4px;
`;

const CopyIcon = styled(BaseCopyIcon)`
  vertical-align: middle;
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin-left: 4px;
`;

const DateText = styled(Text)`
  vertical-align: middle;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: max-content;
  color: ${colors.neutrals[70]};
`;

interface ClientSummaryProps {
  client: DisplayClient;
  lastActivity?: DisplayNote;
}

const ClientSummary = ({ client, lastActivity }: ClientSummaryProps) => (
  <tr>
    <StyledCol>
      {client.clientRep ? (
        <>
          {client.clientRep.avatarImagePath != null && (
            <ClientIcon
              src={client.clientRep.avatarImagePath.toString()}
              alt=""
            />
          )}
          <TbodyText $blue>
            {client.clientRep.firstName} {client.clientRep.lastName}
          </TbodyText>
        </>
      ) : (
        <TbodyText>
          No Client Rep
        </TbodyText>
      )}
    </StyledCol>
    <StyledCol>
      <TbodyText>{client.status != null ? client.status.name : "No Status"}</TbodyText>
    </StyledCol>
    <StyledCol>
      <TbodyText>{client.defaultTerms.name}</TbodyText>
    </StyledCol>
    <StyledCol>
      <TbodyText>{client.defaultTax.label}</TbodyText>
    </StyledCol>
    <StyledCol>
      <TbodyText>{client.minMargin}</TbodyText>
    </StyledCol>
    <StyledCol>
      <TbodyText $blue>
        <Link
          to={"./portal/"}
          rel="opener noreferrer"
          target="_blank"
        >
          Main Portal
        </Link>
      </TbodyText>
    </StyledCol>
    <StyledCol>
      {lastActivity && 
        <>
          <TbodyText $blue dangerouslySetInnerHTML={{ __html: lastActivity.message }} />
          <div>
            <DateText>
              {toTitleCase(lastActivity.type)}
              {", "}
              {formatDateFromString(lastActivity.creationDate.toString())}
            </DateText>
          </div>
        </>
      }
    </StyledCol>
  </tr>
);

export interface DetailsCardProps {
  client: DisplayClient;
  lastActivity: DisplayNote;
}

export const DetailsCard = ({
  client,
  lastActivity,
}: DetailsCardProps) => {
  const {
    scrollRef,
    currentPage,
    totalPages,
    handleScroll,
    calculateScroll,
    scrolledToLeft,
    scrolledToRight,
  } = useScrollCarousel();
  const dispatch = useDispatch();

  return (
    <div>
      <Header>
        <HeaderText>Details</HeaderText>
        <HeaderButtons>
          <HeaderEditIcon onClick={() => {
            dispatch(setEditingDetails(true));
            dispatch(showPopup("view-details"));
          }} />
        </HeaderButtons>
        <HeaderButtons>
          <ViewAll onClick={() => dispatch(showPopup("view-details"))}>
            View All
          </ViewAll>
        </HeaderButtons>
      </Header>
      <ScrollContainer>
        <ScrollIndicator
          $direction="left"
          $visible={!scrolledToLeft}
        />
        <ScrollWrap ref={scrollRef} onScroll={() => handleScroll(0)}>
          <ClientCarousel>
            <tbody>
              <tr>
                <StyledCol $bold>
                  <THeadText>Client Rep</THeadText>
                </StyledCol>
                <StyledCol $bold>
                  <THeadText>Status</THeadText>
                </StyledCol>
                <StyledCol $bold>
                  <THeadText>Default Terms</THeadText>
                </StyledCol>
                <StyledCol $bold>
                  <THeadText>Tax</THeadText>
                </StyledCol>
                <StyledCol $bold>
                  <THeadText>Min Margin</THeadText>
                </StyledCol>
                <StyledCol $bold>
                  <THeadText>Portal</THeadText>
                  <CopyIcon
                    size="medium"
                    altText="Portal"
                    onClick={() =>
                      navigator.clipboard.writeText(client.portal.id)
                    }
                  />
                </StyledCol>
                <StyledCol $bold>
                  <THeadText>Last Activity</THeadText>
                </StyledCol>
              </tr>
              <ClientSummary client={client} lastActivity={lastActivity} />
            </tbody>
          </ClientCarousel>
        </ScrollWrap>
        <ScrollIndicator
          $direction="right"
          $visible={!scrolledToRight}
        />
      </ScrollContainer>
      {scrollRef.current != null && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onChange={calculateScroll}
        />
      )}
    </div>
  );
};
